<!--
 * @Author: zengzewei
 * @Date: 2021-11-23 15:23:47
 * @LastEditTime: 2021-12-10 17:56:07
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \onesports-arc-view\src\views\report\storedValueCardStatistics\index.vue
-->
<template>
    <div class="wrap">
        <div class="search-container">
            <el-row>
                <el-col :span="20">
                    <el-row>
                        <el-form ref="form" :model="form" label-width="100px">
                            <el-col :span="12">
                                <el-form-item label="选择时间：">
                                    <el-date-picker
                                        v-model="form.time"
                                        type="daterange"
                                        :clearable="true"
                                        value-format="yyyy-MM-dd"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-form>
                    </el-row>
                </el-col>
                <el-col :span="4" class="btn">
                    <el-button  class="search-button" @click="searchEvent">{{$t("search")}}</el-button>
                    <el-button  class="clean-button" @click="cleanForm">{{$t("clean")}}</el-button>
                </el-col>
            </el-row>
        </div>
        <div class="table-container">
            <el-table
                :data="tableData"
                :height="tableHeight - 4"
                ref="tableData"
                style="width: 100%"
                border
                :row-class-name="tableRowClassName"
            >
                <el-table-column
					prop="memberName"
                    min-width="200"
                    align="center"
					label="会员名称">
				</el-table-column>
                <el-table-column
					prop="phone"
                    min-width="150"
                    align="center"
					label="手机号">
				</el-table-column>
                <el-table-column
					prop="cardName"
                    min-width="150"
                    align="center"
					label="储值卡名称">
				</el-table-column>
                <el-table-column
					prop="firstRechargeMoney"
                    min-width="100"
                    align="center"
					label="期初充值金额">
				</el-table-column>
                <el-table-column
					prop="rechargeCount"
                    min-width="100"
                    align="center"
					label="充值次数">
				</el-table-column>
                <el-table-column
					prop="rechargeMoney"
                    min-width="100"
                    align="center"
					label="充值金额">
				</el-table-column>
                <el-table-column
					prop="firstGiveMoney"
                    min-width="100"
                    align="center"
					label="期初赠送金额">
				</el-table-column>
                <el-table-column
					prop="giveCount"
                    min-width="100"
                    align="center"
					label="赠送次数">
				</el-table-column>
                <el-table-column
					prop="giveMoney"
                    min-width="100"
                    align="center"
					label="赠送金额">
				</el-table-column>
                <el-table-column
					prop="surplusMoney"
                    min-width="100"
                    align="center"
					label="剩余金额">
				</el-table-column>
            </el-table>
        </div>
        <div class="page-warp" >
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="form.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="form.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="form.total"
            >
            </el-pagination>
            <el-button class="default-button" @click="exportStorageCard" v-if="buttonAuth.includes('venueOperation:storageCardStat:export')">导出数据</el-button>
        </div>
    </div>
</template>

<script>
import mixin from "@/mixins/index";
import apis from "@/apis";
import filwDownload from "@/mixins/download"

export default {
    mixins: [mixin, filwDownload],
    data() {
        return {
            form: {
                time: '',
                endDate: '',
                beginDate: '',
                pageNum: 1,
                pageSize: 10,
                total: 0,
            },
            tableData: [],
        };
    },

    methods: {
        searchData() {
            if (this.form.time && this.form.time.length > 0) {
                this.form.beginDate = this.form.time[0]
                this.form.endDate = this.form.time[1]
            } else {
                this.form.beginDate = ''
                this.form.endDate = ''
            }
            this.$http.post(apis.storedValueCardStatisticsList, this.form).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.rows
                    this.form.total = res.data.total
                    this.pageNum = res.data.pageNum
                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        cleanForm() {
            this.form = {
                time: '',
                endDate: '',
                beginDate: '',
                pageNum: 1,
                pageSize: 10,
                total: 0,
            }
            this.searchEvent();
        },
        exportStorageCard() {
            console.log(123123123);
            this.$http.post(apis.exportStorageCard, {
                beginDate: this.form.beginDate,
                endDate: this.form.endDate,
            }).then((res) => {
                console.log(res)
                this.exportFun(res.data.rows.url, res.data.rows.fileName)
            })
        },
    },
    updated() {
        this.$nextTick(() => {
            this.$refs.tableData.doLayout()
        })
    },
};
</script>

<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.page-warp{
    padding: 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.btn{
    display: flex;
    justify-content: flex-end
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
